// import msdyn365Commerce, { IComponent, IComponentProps } from "@msdyn365-commerce/core-internal";
import React from "react";

export interface IProductNotPurchasableProps{
    isInActiveProduct?: boolean;
    isPriceUnavailable?: boolean;
}

// export interface IProductNotPurchasable extends IComponent<IProductNotPurchasableProps>{}

export const ProductNotPurchasableComponent = (props: IProductNotPurchasableProps): JSX.Element | null => {
    // let isShowMsg: boolean;
    // if(props.isInActiveProduct)
    // isShowMsg = props.isInActiveProduct;
    // if(props.isPriceUnavailable)
    // isShowMsg = props.isPriceUnavailable;
    if(props && props.isInActiveProduct)
    {
        return (<span style={{color:'red', fontSize:'16px'}}>
            Discontinued product
        </span>);
    }
    if(props && props.isPriceUnavailable)
    {
        return (
            <span style={{color:'red', fontSize:'16px'}}>
                You are not authorized to purchase this product. Please contact the Sales team if you wish to purchase this product.
            </span>
        );
    }
return (
    <span className="text"></span>
    );
};


// export const ProductNotPurchasableComponent: React.FunctionComponent<IProductNotPurchasableProps> = msdyn365Commerce.createComponent<IProductNotPurchasable>(
//     'productNotPurchasable',
//     { component: productNotPurchasable }
// );

// export default ProductNotPurchasableComponent;
